import axios from 'axios';

const urlScript =
  process.env.SERVER_MAIL || 'https://api-mail.hairun-technology.com';

export const sendMailContact = (
  data: {
    name: string;
    tel: string;
    mail: string;
    subject: string;
    message: string;
  },
  setSnackBar: React.Dispatch<React.SetStateAction<boolean>>,
  setSnackBarMessage: React.Dispatch<React.SetStateAction<string>>,
  initState: () => void,
) => {
  setSnackBarMessage('Début envoi de mail');
  setSnackBar(true);
  axios({
    method: 'post',
    url: `${urlScript}/api/v1/contact`,
    data,
  })
    .then(response => {
      if (response.data.success) {
        setSnackBarMessage('Mail envoyé');
        setSnackBar(true);
        initState();
      } else {
        setSnackBarMessage('Mail non envoyé, réessayez');
        setSnackBar(true);
      }
    })
    .catch(error => {
      setSnackBarMessage(
        `Mail non envoyé, réessayez, erreur ${error.status || 'réseaux'}`,
      );
      setSnackBar(true);
    });
};
export const sendMailJoinUs = (
  data: {
    name: string;
    tel: string;
    mail: string;
    subject: string;
    message: string;
  },
  setSnackBar: React.Dispatch<React.SetStateAction<boolean>>,
  setSnackBarMessage: React.Dispatch<React.SetStateAction<string>>,
  initState: () => void,
) => {
  setSnackBarMessage('Début envoi de mail');
  setSnackBar(true);
  axios({
    method: 'post',
    url: `${urlScript}/api/v1/contact`,
    data,
  })
    .then(response => {
      if (response.data.success) {
        setSnackBarMessage('Mail envoyé');
        setSnackBar(true);
        initState();
      } else {
        console.log('response:');
        setSnackBarMessage('Mail non envoyé, réessayez');
        setSnackBar(true);
      }
    })
    .catch(error => {
      console.log('error', error);
      setSnackBarMessage(
        `Mail non envoyé, réessayez, erreur ${error.status || 'réseaux'}`,
      );
      setSnackBar(true);
    });
};

export const askDevis = async (
  data: {
    nom: string;
    prenom: string;
    societe: string;
    tel: string;
    mail: string;
    message: string;
    besoin: string;
    file: any[];
    budget: string;
    technos: string;
    fonction: string;
  },
  setSnackBar: React.Dispatch<React.SetStateAction<boolean>>,
  setSnackBarMessage: React.Dispatch<React.SetStateAction<string>>,
  initState: () => void,
) => {
  setSnackBarMessage('Envoi de votre demande');
  setSnackBar(true);
  axios({
    method: 'post',
    url: `${urlScript}/api/v1/quote/new-ask`,
    data,
  })
    .then(response => {
      console.log(response);
      if (response.data.success) {
        setSnackBarMessage('Demande envoyée');
        setSnackBar(true);
      } else {
        setSnackBarMessage('Demande non envoyée, veuillez réessayez svp');
        setSnackBar(true);
      }
      initState();
    })
    .catch(error => {
      setSnackBarMessage(
        `Demande non envoyée, veuillez réessayez svp, erreur : ${error ||
          'réseaux'}`,
      );
      setSnackBar(true);
    });
};
