import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const IconMail = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M68.692,146.716l-5.4,5.6a.1.1,0,0,0,0,.143l3.776,4.1a.673.673,0,0,1,0,.941.645.645,0,0,1-.923,0l-3.761-4.085a.1.1,0,0,0-.145,0l-.918.951a4,4,0,0,1-2.879,1.237,4.081,4.081,0,0,1-2.939-1.273l-.883-.915a.1.1,0,0,0-.145,0L50.718,157.5a.645.645,0,0,1-.923,0,.673.673,0,0,1,0-.941l3.776-4.1a.11.11,0,0,0,0-.143l-5.4-5.6a.1.1,0,0,0-.171.072V158a1.625,1.625,0,0,0,1.6,1.636H67.258a1.625,1.625,0,0,0,1.6-1.636V146.788A.1.1,0,0,0,68.692,146.716Z"
        transform="translate(-48 -144.912)"
      />
      <path
        d="M69.913,121.362a2.7,2.7,0,0,0,1.959-.839l7.859-8.165a1.555,1.555,0,0,0-.992-.358H61.092a1.544,1.544,0,0,0-.992.358l7.859,8.165A2.7,2.7,0,0,0,69.913,121.362Z"
        transform="translate(-59.481 -112)"
      />
    </SvgIcon>
  );
};

export default IconMail;
