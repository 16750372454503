import React, { useState } from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const IconLinkedin = (props: SvgIconProps) => {
  const [hovered, setHovered] = useState(false);
  return (
    <SvgIcon
      {...props}
      onMouseOver={() => setHovered(!hovered)}
      onFocus={() => setHovered(!hovered)}
      onMouseOut={() => setHovered(!hovered)}
      onBlur={() => setHovered(!hovered)}
    >
      <rect
        xmlns="http://www.w3.org/2000/svg"
        id="Rectangle_1838"
        data-name="Rectangle 1838"
        width="32"
        height="32"
        fill="none"
      />
      <path
        xmlns="http://www.w3.org/2000/svg"
        id="Tracé_5891"
        data-name="Tracé 5891"
        d="M152.647,413.225c-3.753,0-7.507-.013-11.26.008a1.951,1.951,0,0,1-1.975-1.633,2.053,2.053,0,0,1-.02-.3q0-11.365,0-22.731a1.883,1.883,0,0,1,1.91-1.93c1.2-.009,2.4,0,3.6,0q9.533,0,19.065-.007a1.931,1.931,0,0,1,1.9,1.354,2.271,2.271,0,0,1,.083.561q.006,11.378,0,22.755a1.881,1.881,0,0,1-1.9,1.917c-1.417.01-2.834,0-4.251,0Zm.909-15.014c0-.429-.009-.89,0-1.35.006-.214-.056-.289-.279-.286q-1.64.016-3.281,0c-.246,0-.3.08-.3.313q.009,6.074,0,12.148c0,.236.065.319.307.317q1.7-.015,3.405,0c.241,0,.307-.077.306-.314q-.013-3.037,0-6.074a7.829,7.829,0,0,1,.1-1.285,1.973,1.973,0,0,1,1.453-1.793,3.463,3.463,0,0,1,.883-.085,1.626,1.626,0,0,1,1.74,1.383,7.344,7.344,0,0,1,.164,1.549c.018,2.091.007,4.182.008,6.273,0,.332.007.34.329.34,1.119,0,2.237-.006,3.355.005.253,0,.32-.076.319-.327-.009-2.423.019-4.846-.02-7.269a11.639,11.639,0,0,0-.3-2.405,3.556,3.556,0,0,0-2.589-2.843,9.262,9.262,0,0,0-1.537-.231,4.008,4.008,0,0,0-3.075.914A12.254,12.254,0,0,0,153.556,398.211Zm-10.273,4.761c0,2.024,0,4.048-.005,6.072,0,.245.079.31.312.308q1.7-.014,3.4,0c.218,0,.3-.058.3-.3q-.01-6.1,0-12.194c0-.241-.088-.288-.3-.287-1.118.009-2.236,0-3.354,0-.355,0-.355,0-.355.346Q143.283,399.949,143.283,402.972Zm1.988-8.1a2.309,2.309,0,1,0-2.289-2.31A2.294,2.294,0,0,0,145.271,394.874Z"
        transform="translate(-136.621 -383.969)"
        fill={!hovered ? props.htmlColor : '#2867B2'}
      />
    </SvgIcon>
  );
};

export default IconLinkedin;
