import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  
  socialMedia: {
    display: 'flex',
    alignItems: 'center',
    '& a': {
      marginRight: theme.spacing(5),
      width: 25,
      height: 25,
      '& svg': {
        width: '100%',
        transition: 'color ease 0.5s',
      },
      '&:first-of-type:hover svg': {
        color: '#4267B2',
      },
      '&:nth-of-type(2):hover svg': {
        color: '#00ACED',
      },
      '&:nth-of-type(3):hover svg': {
        color: '#0077B5',
      },
      '&:nth-of-type(4):hover svg': {
        color: '#CD2029',
      },
      '@media (max-width:576px)': {
        marginRight: 0,
      },
    },
    '@media (max-width:576px)': {
      width: '100%',
      justifyContent: 'space-between',
    },
  },
}));
