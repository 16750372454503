import React from 'react';
import { Box, Link } from '@material-ui/core';
import styles from './style';

import IconFacebook from '../Icons/IconFacebook';
import IconTwitter from '../Icons/IconTwitter';
import IconLinkedin from '../Icons/IconLinkedin';
import IconYoutube from '../Icons/IconYoutube';

interface PropTypes {
  htmlColor: string;
}
const SocialNetwork = ({ htmlColor }: PropTypes) => {
  const classes = styles();

  return (
    <Box className={classes.socialMedia}>
      <Link
        href="https://www.facebook.com/HaiRunTechnology/"
        color="inherit"
        underline="none"
      >
        <IconFacebook
          htmlColor={htmlColor}
          viewBox="0 0 32 32"
          width="32"
          height="32"
        />
      </Link>
      <Link
        href="https://twitter.com/HaiRun_Tech"
        color="inherit"
        underline="none"
      >
        <IconTwitter
          htmlColor={htmlColor}
          width="32"
          height="32"
          viewBox="0 0 32 32"
        />
      </Link>
      <Link
        href="https://www.linkedin.com/company/hairuntechnology/"
        color="inherit"
        underline="none"
      >
        <IconLinkedin
          htmlColor={htmlColor}
          width="32"
          height="32"
          viewBox="0 0 32 32"
        />
      </Link>
      <Link
        href="https://www.youtube.com/channel/UCk-xzDl8PbU7swhfkqGm4OQ?view_as=subscriber"
        color="inherit"
        underline="none"
      >
        <IconYoutube
          htmlColor={htmlColor}
          viewBox="0 0 32 32"
          width="32"
          height="32"
        />
      </Link>
    </Box>
  );
};

export default SocialNetwork;
