import React, { useState } from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const IconTwitter = (props: SvgIconProps) => {
  const [hovered, setHovered] = useState(false);
  return (
    <SvgIcon
      {...props}
      onMouseOver={() => setHovered(!hovered)}
      onFocus={() => setHovered(!hovered)}
      onMouseOut={() => setHovered(!hovered)}
      onBlur={() => setHovered(!hovered)}
    >
      <rect
        xmlns="http://www.w3.org/2000/svg"
        id="Rectangle_1837"
        data-name="Rectangle 1837"
        width="32"
        height="32"
        fill="none"
      />
      <path
        xmlns="http://www.w3.org/2000/svg"
        id="Tracé_5888"
        data-name="Tracé 5888"
        d="M83.744,121.043c10.492,0,16.231-8.693,16.231-16.231,0-.247,0-.493-.017-.737a11.607,11.607,0,0,0,2.846-2.953,11.387,11.387,0,0,1-3.276.9,5.724,5.724,0,0,0,2.508-3.155,11.431,11.431,0,0,1-3.622,1.385,5.71,5.71,0,0,0-9.721,5.2,16.2,16.2,0,0,1-11.757-5.96,5.709,5.709,0,0,0,1.766,7.615,5.662,5.662,0,0,1-2.589-.714v.072a5.706,5.706,0,0,0,4.577,5.592,5.7,5.7,0,0,1-2.576.1,5.711,5.711,0,0,0,5.329,3.962,11.446,11.446,0,0,1-7.084,2.447A11.614,11.614,0,0,1,75,118.48a16.15,16.15,0,0,0,8.744,2.558"
        transform="translate(-72.902 -93.744)"
        fill={!hovered ? props.htmlColor : '#1DA1F2'}
      />
    </SvgIcon>
  );
};

export default IconTwitter;
