import React, { useState } from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const IconYoutube = (props: SvgIconProps) => {
  const [hovered, setHovered] = useState(false);
  return (
    <SvgIcon
      {...props}
      onMouseOver={() => setHovered(!hovered)}
      onFocus={() => setHovered(!hovered)}
      onMouseOut={() => setHovered(!hovered)}
      onBlur={() => setHovered(!hovered)}
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        id="Tracé_5894"
        data-name="Tracé 5894"
        d="M0,0H32V32H0Z"
        fill="none"
      />
      <g
        xmlns="http://www.w3.org/2000/svg"
        id="Groupe_2910"
        data-name="Groupe 2910"
        transform="translate(-6.666 -28.332)"
      >
        <path
          id="Soustraction_1"
          data-name="Soustraction 1"
          d="M22045.334,20172c-.092,0-9.2-.008-11.461-.621a3.7,3.7,0,0,1-2.6-2.607,41.73,41.73,0,0,1,0-14.211,3.7,3.7,0,0,1,2.6-2.613c2.258-.607,11.369-.615,11.461-.615s9.2.008,11.459.615a3.688,3.688,0,0,1,2.592,2.613,41.339,41.339,0,0,1,0,14.211,3.677,3.677,0,0,1-2.592,2.607C22054.531,20171.992,22045.426,20172,22045.334,20172Zm-3-14.693h0v8.719l7.668-4.357-7.668-4.361Z"
          transform="translate(-22022.666 -20117.332)"
          fill={!hovered ? props.htmlColor : '#FF0000'}
          stroke="rgba(0,0,0,0)"
          strokeMiterlimit="10"
          strokeWidth="1"
        />
      </g>
    </SvgIcon>
  );
};

export default IconYoutube;
