import React, { useState } from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const IconFacebook = (props: SvgIconProps) => {
  const [hovered, setHovered] = useState(false);
  return (
    <SvgIcon
      {...props}
      onMouseOver={() => setHovered(!hovered)}
      onFocus={() => setHovered(!hovered)}
      onMouseOut={() => setHovered(!hovered)}
      onBlur={() => setHovered(!hovered)}
    >
      <rect
        xmlns="http://www.w3.org/2000/svg"
        id="Rectangle_1836"
        data-name="Rectangle 1836"
        width="32"
        height="32"
        fill="none"
      />
      <g
        xmlns="http://www.w3.org/2000/svg"
        id="Groupe_2909"
        data-name="Groupe 2909"
        transform="translate(2.699 2.666)"
      >
        <path
          id="Tracé_5886"
          data-name="Tracé 5886"
          d="M26.6,13.3A13.3,13.3,0,1,0,11.222,26.438V17.144H7.845V13.3h3.377V10.37c0-3.333,1.986-5.174,5.024-5.174a20.452,20.452,0,0,1,2.977.26V8.728H17.545A1.922,1.922,0,0,0,15.378,10.8V13.3h3.689l-.59,3.844h-3.1v9.294A13.3,13.3,0,0,0,26.6,13.3Z"
          fill={!hovered ? props.htmlColor : '#1877F2'}
        />
        <path
          id="Tracé_5887"
          data-name="Tracé 5887"
          d="M312.632,211.949l.59-3.844h-3.689V205.61a1.922,1.922,0,0,1,2.167-2.077h1.677V200.26A20.454,20.454,0,0,0,310.4,200c-3.038,0-5.023,1.841-5.023,5.174v2.93H302v3.844h3.377v9.294a13.439,13.439,0,0,0,4.156,0v-9.294Z"
          transform="translate(-294.155 -194.805)"
          fill="none"
        />
      </g>
    </SvgIcon>
  );
};

export default IconFacebook;
