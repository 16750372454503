import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const IconPhone = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        id="ios-call"
        d="M81.89,78.574a15.6,15.6,0,0,0-3.258-2.18c-.976-.469-1.334-.459-2.025.039-.575.416-.947.8-1.61.657A9.6,9.6,0,0,1,71.763,74.7a9.534,9.534,0,0,1-2.393-3.234c-.14-.667.247-1.034.657-1.61.5-.691.512-1.049.039-2.025a15.288,15.288,0,0,0-2.18-3.258c-.711-.711-.87-.556-1.262-.416a7.179,7.179,0,0,0-1.155.614,3.485,3.485,0,0,0-1.387,1.465c-.276.595-.595,1.7,1.03,4.592a25.624,25.624,0,0,0,4.505,6.009l0,0,0,0a25.724,25.724,0,0,0,6.009,4.505c2.891,1.624,4,1.305,4.592,1.03a3.426,3.426,0,0,0,1.465-1.387,7.179,7.179,0,0,0,.614-1.155C82.446,79.444,82.605,79.285,81.89,78.574Z"
        transform="translate(-63.857 -64.035)"
      />
    </SvgIcon>
  );
};

export default IconPhone;
